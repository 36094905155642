<template>
    <div class="name">
        <label>
            NAME
            <input v-model="name" placeholder="Template Name"
                   :class="[isNameError() ? 'error' : '']">
        </label>
    </div>
</template>

<script>
export default {
    name: "NameStep",
    props: {
        value: String,
        errors: Object,
    },
    computed: {
        name: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
    methods: {
        isNameError: function () {
            return this.errors !== null && 'name' in this.errors;
        }
    }
}
</script>

<style scoped>

</style>
