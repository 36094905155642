const showSuccessMessage = (message) => {
    const messageBox = document.querySelector('.messages.success');

    messageBox.innerHTML = message;
    messageBox.classList.toggle('show');
    window.setTimeout(function () {
        messageBox.classList.toggle('show');
    }, 3000);
};

const showErrorMessage = (errorObj) => {
    const messageBox = document.querySelector('.messages.error');

    messageBox.innerHTML = JSON.stringify(errorObj.errors);
    messageBox.classList.toggle('show');
    window.setTimeout(function () {
        messageBox.classList.toggle('show');
    }, 5000);
};

export { showSuccessMessage, showErrorMessage };
