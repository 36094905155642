<template>
  <div id="template-wizard-component">
    <form-wizard @on-complete="saveTemplate"
                 @on-loading="renderLoader"
                 @on-error="handleErrorMessage"
                 :start-index="this.startIndex === '2' ? 2 : 0"
                 :back-button-text="translate('BACK')"
                 :next-button-text="translate('NEXT')"
                 :finish-button-text="translate('FINISH')"
                 shape="square"
                 color="#11388d"
                 error-color="#e60f46"
                 title=""
                 subtitle="">

      <div class="errors" v-if="errors !== null && Object.keys(errors).length > 0">
        <div class="error" v-for="(error, key) in errors">
          {{ key }} -
          <span v-if="typeof error === 'object'">
                        <span v-for="err in error">
                            {{ err }}
                        </span>
                    </span>
          <span v-else>
                        {{ error }}
                    </span>
        </div>
      </div>

      <tab-content title="1"
                   :before-change="validateName">

        <!-- Step 1: Enter Name -->
        <name-step
            :value="template.name"
            :errors="errors"
            @input="template.name = $event">
        </name-step>

      </tab-content>
      <tab-content title="2"
                   :before-change="validateCountries">

        <!-- Step 2: Select Countries -->
        <countries-step
            :value="template.countries"
            :all-countries="allCountries"
            :translations="translations"
            :errors="errors"
            @input="template.countries = $event">
        </countries-step>

      </tab-content>
      <tab-content title="3">

        <!-- Step 3: Upload ZIP -->
        <zip-step :preview-html="template.previewHtml"
                  :translations="translations"
                  @changeHtml="template.previewHtml = $event"
                  @uploadError="handleErrorMessage"></zip-step>

      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import ZipStep from "./Steps/ZipStep";
import NameStep from "./Steps/NameStep";
import CountriesStep from "./Steps/CountriesStep";
import * as ApiUrl from "../modules/ApiUrl";
import axios from 'axios';

export default {
  name: 'TemplateWizard',
  props: {
    allCountries: Object,
    startIndex: String,
    existingTemplate: Object,
    translations: Object,
  },
  components: {
    CountriesStep,
    NameStep,
    ZipStep
  },
  data () {
    return {
      template: {
        templateId: '',
        name: '',
        countries: [],
        previewHtml: '',
      },
      loadingWizard: false,
      errors: {},
    }
  },
  methods: {
    translate: function (translationKey) {
      return this.translations[translationKey];
    },
    renderLoader: function () {
      document.getElementById('loader').classList.toggle('hidden');
    },
    handleErrorMessage: function (errors) {
      this.errors = errors
    },
    saveTemplate: async function () {
      return await axios.post(
          ApiUrl.apiUrl(),
          this.template
      )
          .then((response) => {
            window.location.assign(response.data.redirectTo);

            return response;
          })
          .catch((error) => {
            this.handleErrorMessage(error.response.data.errors)
          });
    },
    validateName: async function () {
      return await axios.post(
          ApiUrl.validateTemplateNameUrl(),
          {name: this.template.name}
      )
          .then((result) => {
            this.template.name = result.data.name
            this.errors = {}

            document.getElementById('step-12').classList.add('completed')

            return Promise.resolve(true);
          })
          .catch(error => Promise.reject(error.response.data.errors));
    },
    validateCountries: async function () {
      return await axios.post(
          ApiUrl.validateTemplateCountriesUrl(),
          {countries: this.template.countries}
      )
          .then((result) => {
            this.template.countries = result.data.countries;
            this.errors = {}

            document.getElementById('step-24').classList.add('completed')

            return Promise.resolve(true);
          })
          .catch(error => Promise.reject(error.response.data.errors));
    }
  },
  created () {
    this.template = this.existingTemplate;
  },
};
</script>

<style>

</style>
