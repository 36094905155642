<template>
    <div class="countries">
        <div class="checkboxes" :class="[isCountriesError() ? 'error' : '']">
            <div class="checkbox">
                <label>
                    {{ translate('CHECK_ALL') }}
                    <input type="checkbox" class="input" id="check-all" @click="checkAll" v-model="isCheckAll">
                    <span class="checkmark">
                        <i class="fa fa-check check"></i>
                    </span>
                </label>
            </div>
            <div class="checkbox" v-for="(countryName, countryCode) in allCountries">
                <label>
                    {{ countryName }}
                    <input type="checkbox" class="input" :value="countryCode" v-model="countries" @change="updateCheckAll">
                    <span class="checkmark">
                        <i class="fa fa-check check"></i>
                    </span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CountriesStep",
        props: {
            value: Array,
            allCountries: Object,
            errors: Object,
            translations: Object,
        },
        data () {
            return {
                isCheckAll: false
            };
        },
        computed: {
            countries: {
                get () {
                    return this.value;
                },
                set (value) {
                    this.$emit('input', value);
                },
            }
        },
        methods: {
            translate: function (translationKey) {
                return this.translations[translationKey];
            },
            checkAll: function () {
                this.isCheckAll = !this.isCheckAll;
                this.value = [];

                if (this.isCheckAll) {
                    for (let countryCode in this.allCountries) {
                        this.value.push(countryCode);
                    }
                }
            },
            updateCheckAll: function () {
                this.isCheckAll = this.value.length === Object.keys(this.allCountries).length;
            },
            isCountriesError: function () {
                return this.errors !== null && 'countries' in this.errors;
            }
        },
        created() {
            this.updateCheckAll()
        },
    }
</script>

<style scoped>

</style>
