<template>
    <div class="upload-container">
       <div v-if="cMenuCard.imageAreas.length === 0" style="text-align: center">
         <label>{{ translate('NO_IMAGE_AREAS_PRESS_FINISH') }}</label>
       </div>
        <div v-for="area in cMenuCard.imageAreas ">
            <h2>{{ renameImageArea(area.name) }}</h2>
            <label :for="area.name" class="btn upload-btn" hidden>Add File/s</label>
            <input type="file"
                   :id="area.name"
                   name="upload"
                   :data-upload-uri="uploadUri()"
                   @change="multiUpload"
                   multiple
                   hidden
            >
            <div id="upload-drop"
                 :data-upload-id="area.name"
                 class="upload-drop"
                 :data-upload-uri="uploadUri()"
                 :class="{ error: isUploadError() }"
                 @dragover="onDrag"
                 @dragleave="onDragEnd"
                 @drop="onDrop"
                 @click="clickUpload"
            >
            <span class="desktop">
                {{ translate('DRAG_DROP_FILES_HERE') }}<br>
                {{ translate('ALLOWED_FORMATS') }}: png, jpg, tiff, pdf
            </span>
                <span class="mobile">
                {{ translate('TOUCH_TO_UPLOAD') }}<br>
                {{ translate('ALLOWED_FORMATS') }}: png, jpg, tiff, pdf
            </span>
            </div>

            <div class="images-info">
                {{ translate('DRAG_DROP_SORT_IMAGES') }}
            </div>

            <draggable v-model="cMenuCard.imageAreas[area.name].images" group="images" @start="drag=true"
                       @end="drag=false" class="images">
                <div v-for="(image, index) in cMenuCard.imageAreas[area.name].images"
                     :key="cMenuCard.imageAreas[area.name].images.indexOf(image)" class="image">
                    <img :src="image.path" alt="Menu Card Image">
                    <a :data-image="index" :data-upload-id="area.name" class="delete-image" @click="deleteImage">
                        <span>×</span>
                    </a>
                </div>
            </draggable>
        </div>

    </div>
</template>

<script>
import * as Loader from "../modules/Loader";
import * as Messages from "../modules/Messages";
import axios from "axios";
import draggable from 'vuedraggable';
import * as ApiUrl from '../modules/ApiUrl';

export default {
    name: "ImagesStep",
    components: {
        draggable,
    },
    props: {
        menuCard: Object,
        errors: Object,
        translations: Object,
    },
    watch: {
        'cMenuCard.templateId': function () {
            this.loadImageAreas();
        }
    },
    computed: {
        cMenuCard: {
            get() {
                return this.menuCard;
            },
            set(val) {
                this.$emit('updateMenuCard', val);
            }
        },
    },
    methods: {
        translate: function (translationKey) {
          return this.translations[translationKey];
        },
        uploadUri: function () {
            return ApiUrl.uploadMenuCardImages();
        },
        multiUpload: async function (event) {
            Loader.apply();

            const input = event.currentTarget;
            const files = input.files;
            const url = input.getAttribute('data-upload-uri');
            const area = input.getAttribute('id');

            for (const file of files) {
                await this.uploadAndHandleFile(url, this.createRequestBody(file), area);
            }

            input.value = null;
        },
        singleUpload: async function (event) {
            Loader.apply();

            const input = event.currentTarget;
            const file = input.files[0];
            const url = input.getAttribute('data-upload-uri');
            const area = input.getAttribute('id');

            await this.uploadAndHandleFile(url, this.createRequestBody(file), area);

            input.value = null;
        },
        uploadAndHandleFile: async function (url, body, area) {
            await axios.post(url, body, {
                headers: {'Content-Type': 'multipart/form-data'}
            })
                .then(response => response.data)
                .then((data) => {
                    for (let imagePath of data.images) {
                        const image = {
                            path: imagePath,
                            area: area
                        };

                        this.cMenuCard.imageAreas[area].images.push(image);
                    }

                    if (data.info !== undefined) {
                        Messages.showInfoMessage(data.info);
                    }
                })
                .then(() => {
                    this.$emit('error', {});
                    document.getElementById('step-36').classList.add('completed');
                })
                .catch((error) => {
                    console.log(error);
                    this.$emit('error', error.response.data.errors);
                })
                .finally(() => Loader.remove())
        },
        createRequestBody: function (file) {
            const formData = new FormData;
            formData.append('file', file, file.name);

            return formData;
        },
        loadImageAreas: async function () {
            return await axios.get(ApiUrl.loadImageAreas(this.cMenuCard.templateId))
                .then((response) => {
                    this.cMenuCard.imageAreas = response.data;
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        deleteImage: function (event) {
            const area = event.currentTarget.getAttribute('data-upload-id');
            const image = event.currentTarget.getAttribute('data-image');
            this.cMenuCard.imageAreas[area].images.splice(image, 1);
        },
        dropUpload: async function (event) {
            Loader.apply();

            const dropzone = event.currentTarget;
            const area = dropzone.getAttribute('data-upload-id');
            const input = document.getElementById(area);

            if (input.hasAttribute('multiple')) {
                for (const logo of event.dataTransfer.files) {
                    await this.uploadAndHandleFile(
                        dropzone.getAttribute('data-upload-uri'),
                        this.createRequestBody(logo),
                        area
                    );
                }
            } else {
                await this.uploadAndHandleFile(
                    dropzone.getAttribute('data-upload-uri'),
                    event.dataTransfer.files[0],
                    area
                );
            }
        },
        onDrop: function (event) {
            this.killDefaults(event);
            this.onDragEnd(event);
            this.dropUpload(event);
        },
        onDrag: function (event) {
            this.killDefaults(event);

            const dropZone = event.currentTarget;
            dropZone.classList.add('file-over');
        },
        onDragEnd: function (event) {
            this.killDefaults(event);

            const dropZone = event.currentTarget;
            dropZone.classList.remove('file-over');
        },
        clickUpload: function (e) {
            document.getElementById(e.currentTarget.getAttribute('data-upload-id')).click();
        },
        renameImageArea: function (placeholder) {
            return placeholder.replace('__IMAGE_AREA__', '')
                .replace('__', '')
                .replace('_', ' ');
        },
        killDefaults: function (event) {
            event.preventDefault();
            event.stopPropagation();
        },
        isUploadError: function () {
            return this.errors !== null && 'image' in this.errors;
        },
    }
}
</script>

<style scoped>

</style>
