// JS
import Vue from 'vue/dist/vue.common';
import VueFormWizard from 'vue-form-wizard/src/index';
import TemplateWizard from "./js/TemplateWizard/TemplateWizard";
import MenuCardWizard from "./js/MenuCardWizard/MenuCardWizard";

// CSS
import './css/index.scss';

if (document.querySelector('#template-wizard')) {
    Vue.use(VueFormWizard)
    new Vue({
        el: '#template-wizard',
        components: {TemplateWizard}
    })
}

if (document.querySelector('#menu-card-wizard')) {
    Vue.use(VueFormWizard)
    new Vue({
        el: '#menu-card-wizard',
        components: {MenuCardWizard}
    })
}
