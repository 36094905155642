<template>
    <div id="menu-card-wizard-component">
        <form-wizard @on-complete="saveMenuCard"
                     @on-loading="renderLoader"
                     @on-error="handleErrorMessage"
                     :start-index="parseInt(startIndex)"
                     :back-button-text="translate('BACK')"
                     :next-button-text="translate('NEXT')"
                     :finish-button-text="translate('FINISH')"
                     shape="tab"
                     color="#253d6c"
                     error-color="#e60f46"
                     title=""
                     subtitle="">

            <div class="errors" v-if="errors !== null && Object.keys(errors).length > 0">
                <div class="error" v-for="(error, key) in errors">
                    {{ key }} -
                    <span v-if="typeof error === 'object'">
                        <span v-for="err in error">
                            {{ err }}
                        </span>
                    </span>
                    <span v-else>
                        {{ error }}
                    </span>
                </div>
            </div>

            <tab-content title="1"
                         :before-change="validateNameAndCustomer">

                <div class="wizard-step-info">
                    <h3>{{ translate('NAME_YOUR_DIGITAL_CONTENT') }}</h3>
                </div>

                <!-- Step 1: Enter Name & Select Customer -->
                <name-and-customer-step
                    :menu-card="menuCard"
                    :errors="errors"
                    :translations="translations"
                    @updateMenuCard="menuCard = $event"
                >
                </name-and-customer-step>

            </tab-content>

            <tab-content title="2"
                         :before-change="validatePlaceholders">

                <div class="wizard-step-info">
                    <h3>{{ translate('SELECT_A_TEMPLATE') }}</h3>
                </div>

                <!-- Step 2: Select Template & Input Placeholder Values -->
                <template-and-placeholders-step
                    :all-countries="allCountries"
                    :menu-card="menuCard"
                    :placeholders="menuCard.placeholders"
                    :templates="allTemplates"
                    :errors="errors"
                    @updatePlaceholders="updatePlaceholders($event)"
                    @error="handleErrorMessage"
                >
                </template-and-placeholders-step>

            </tab-content>

            <tab-content title="3">

                <div class="wizard-step-info">
                    <h3>
                      {{ translate("PLEASE_UPLOAD") }}
                      <span class="customer-option">
                        {{ translate("ACCOUNTS") }}
                      </span>
                      {{ translate('MENU_CARD_SENTENCE') }}
                      (png, jpg, tiff, pdf)
                    </h3>
                </div>

                <!-- Step 3: Upload Images -->
                <images-step
                    :menu-card="menuCard"
                    :errors="errors"
                    :translations="translations"
                    @updateMenuCard="menuCard = $event"
                    @error="handleErrorMessage"
                >
                </images-step>

            </tab-content>
        </form-wizard>
    </div>
</template>

<script>
import NameAndCustomerStep from "./NameAndCustomerStep";
import TemplateAndPlaceholdersStep from "./TemplateAndPlaceholdersStep";
import ImagesStep from "./ImagesStep";
import axios from 'axios';
import * as ApiUrl from './../modules/ApiUrl.js';
import * as Loader from './../modules/Loader.js';

export default {
    name: 'MenuCardWizard',
    props: {
        startIndex: String,
        existingMenuCard: Object,
        allTemplates: Array,
        translations: Object,
        allCountries: Object,
    },
    components: {
        NameAndCustomerStep,
        TemplateAndPlaceholdersStep,
        ImagesStep,
    },
    data() {
        return {
            menuCard: {
                menuCardId: '',
                name: '',
                customerId: '',
                templateId: '',
                placeholders: [],
                imageAreas: {},
            },
            loadingWizard: false,
            errors: {},
        }
    },
    methods: {
        translate: function (translationKey) {
          return this.translations[translationKey];
        },
        updatePlaceholders: function (placeholders) {
            this.menuCard = { ...this.menuCard, placeholders };
        },
        saveMenuCard: async function () {
            Loader.apply();

            if (!this.validateImages()) {
                Loader.remove();

                return false;
            }

            return await axios.post(
                ApiUrl.apiUrl(true),
                this.menuCard
            )
                .then((response) => {
                    Loader.remove();
                    window.location.assign(response.data.redirectTo);

                    return response;
                })
                .catch((error) => {
                    this.handleErrorMessage(error.response.data.errors)
                });
        },
        validateImages: function () {
            const errors = {};

            for (const area of Object.values(this.menuCard.imageAreas)) {
                if (area.images.length === 0) {
                    errors[area.name] = 'Upload at least one asset';
                }
            }

            this.handleErrorMessage(errors);

            return Object.keys(errors).length === 0;
        },
        validateNameAndCustomer: async function () {
            return await axios.post(
                ApiUrl.validateMenuCardNameAndCustomer(),
                {
                    name: this.menuCard.name,
                    customerId: this.menuCard.customerId
                }
            )
                .then((response) => {
                    this.menuCard.name = response.data.name
                    this.menuCard.customerId = response.data.customerId
                    this.errors = {}

                    document.getElementById('step-12').classList.add('completed')

                    return Promise.resolve(true);
                })
                .catch(error => Promise.reject(error.response.data.errors));
        },
        validatePlaceholders: async function () {
            if (this.menuCard.templateId === null) {
                return Promise.reject({Template: 'Value is required and can\'t be empty'});
            }

            return await axios.post(
                ApiUrl.validatePlaceholders(this.menuCard.templateId),
                this.menuCard.placeholders
            )
                .then((response) => {
                    this.menuCard.placeholders = response.data
                    this.errors = {}

                    document.getElementById('step-24').classList.add('completed')

                    return Promise.resolve(true);
                })
                .catch(error => Promise.reject(error.response.data.errors));
        },
        renderLoader: function () {
            Loader.toggle();
        },
        handleErrorMessage: function (errors) {
            this.errors = errors
        },
    },
    created() {
        this.menuCard = Object.assign(this.menuCard, this.existingMenuCard);
    },
};
</script>

<style>

</style>
