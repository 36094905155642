<template>
    <div class="customer-select customer-option">
        <label>
            {{ translate('ACCOUNTS_UC') }}
            <input type="text"
                   id="customer-search"
                   :placeholder="translate('TYPE_TO_SEARCH_ACCOUNT')" @input="getCustomerList"
                   v-model="name"
            />
        </label>
        <div class="filtered-customers" v-if="Object.keys(customerListStructure).length > 0">
            <div v-for="(cities, country) in customerListStructure" class="countries">
                <div class="country">
                    {{ country }}
                </div>
                <div v-for="(customers, city) in cities" class="cities">
                    <div class="city">
                        {{ city }}
                    </div>
                    <div v-for="customer in customers" class="customers">
                        <div class="customer" @click="setValue(customer)">
                            {{ customer.name }}, {{ getStreet(customer) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="name.length >= 3" class="filtered-customers">
            <div class="country" v-if="loading">
                Loading ...
            </div>
            <div class="country" v-else-if="!customerId">
                {{ translate('NO_ACCOUNT_FOUND') }}
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import * as Loader from "../modules/Loader";

export default {
    name: "CustomerSelect",
    props: {
      value: String,
      translations: Object,
    },
    data () {
        return {
            customers: [],
            name: '',
            timeout: null,
            loading: false,
        }
    },
    computed: {
        customerId: {
            get () {
                return this.value;
            },
            set (val) {
                this.$emit('changeCustomer', val)
            }
        },
        customerListStructure: {
            get() {
                const structure = {};

                for (let customer of this.customers) {
                    const country = this.getCountry(customer),
                        city = this.getCity(customer);

                    if (!(country in structure)) {
                        structure[country] = {};
                    }

                    if (!(city in structure[country])) {
                        structure[country][city] = [];
                    }

                    structure[country][city].push(customer);
                }

                const orderedStructure = {}
                Object.keys(structure).sort().forEach(function(country) {
                    orderedStructure[country] = structure[country];

                    const orderedCites = {};
                    Object.keys(orderedStructure[country]).sort().forEach(function(city) {
                        orderedCites[city] = orderedStructure[country][city]
                    })

                    orderedStructure[country] = orderedCites
                });

                return orderedStructure;
            }
        }
    },
    methods: {
        translate: function (translationKey) {
          return this.translations[translationKey];
        },
        setValue (customer) {
            this.customerId = customer.customerId;
            this.name = customer.name
            this.customers = []
            this.street = ''
        },
        async fetchCustomers () {
            Loader.apply()

            await axios.get(`/api/customers?name=${this.name}`)
                .then(response => response.data)
                .then(data => this.customers = data)
                .catch(error => console.log(error));

            Loader.remove()

            this.loading = false
        },
        async getCustomerList () {
            this.customerId = null
            this.loading = true

            if (this.name.length < 3) {
                this.customers = [];
                clearTimeout(this.timeout);

                return false;
            }

            clearTimeout(this.timeout)
            this.timeout = setTimeout(this.fetchCustomers, 500)
        },
        async getCustomer () {
            if (this.customerId !== '' && this.customerId !== null) {
                await axios.get(`/api/customers/${this.customerId}`)
                    .then(response => response.data)
                    .then(data => this.name = data.name)
                    .catch(error => console.log(error));
            }
        },
        getStreet (customer) {
            return customer.street !== '' ? customer.street : 'Unknown Street';
        },
        getCity (customer) {
            return customer.city !== '' ? customer.city : 'Unknown City';
        },
        getCountry (customer) {
            return customer.country !== '' ? customer.country : 'Unknown Country';
        }
    },
    mounted () {
        this.getCustomer()
    }
}
</script>
