<template>
    <div class="menu-card-step">
        <div class="placeholders" v-if="cPlaceholders.length">
            <h3>Fill Placeholder Texts</h3>
            <div v-for="(placeholder, index) in cPlaceholders" class="placeholder">
                <label style="display: block">
                    <span>{{ cleanPlaceholderName(placeholder.name) }}</span>
                    <select
                        v-if="placeholder.name.startsWith('__IOC')"
                        v-model="placeholder.value"
                        @change="updatePlaceholder(index, placeholder)"
                        :id="placeholder.name"
                        :name="placeholder.name"
                    >
                        <option value="" disabled selected>Select the country for data protection regulations</option>
                        <option v-for="(country, ioc) in allCountries" :value=ioc>
                            {{ country }}
                        </option>
                    </select>
                    <input
                        v-if="placeholder.name.startsWith('__DAT')"
                        v-model="placeholder.value"
                        @change="updatePlaceholder(index, placeholder)"
                        type="datetime-local"
                        :id="placeholder.name"
                        :name="placeholder.name"
                        :class="[isPlaceholdersError() ? 'error' : '']"
                    >
                    <input
                        v-else
                        v-model="placeholder.value"
                        @change="updatePlaceholder(index, placeholder)"
                        type="text"
                        :id="placeholder.name"
                        :name="placeholder.name"
                        :class="[isPlaceholdersError() ? 'error' : '']"
                        :placeholder=cleanPlaceholderName(placeholder.name)
                    >
                </label>
                <br>
            </div>
        </div>
        <div class="radios">
            <div class="radio" v-for="(template, index) in templates">
                <label :class="[ template.templateId === menuCard.templateId ? 'checked' : '' ]">
                    <span class="index">#{{ index + 1 }}</span>
                    <span class="title">{{ template.name }}</span>
                    <img :src="template.previewImage" :alt="template.name">
                    <input
                        type="radio"
                        class="input"
                        :value="template.templateId"
                        v-model="menuCard.templateId"
                        @change="loadPlaceholders">
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import * as ApiUrl from './../modules/ApiUrl.js';

export default {
    name: "TemplateAndPlaceholdersStep",
    props: {
        allCountries: Object,
        placeholders: Array,
        menuCard: Object,
        templates: Array,
        errors: Object,
    },
    computed: {
      cPlaceholders: {
        get() {
          return this.placeholders.map(p => {
            return {
              ...p,
              value: p.name.startsWith('__DAT') ? this.dateTimeInputFormat(p.value) : p.value,
            }
          })
        },
        set(val) {
          this.$emit(
              'updatePlaceholders',
              val.map(p => {
                return {
                  ...p,
                  value: p.name.startsWith('__DAT') ? p.value.replace('T', ' ') : p.value,
                }
              })
          )
        }
      }
    },
    methods: {
        loadPlaceholders: async function () {
            return await axios.get(ApiUrl.loadPlaceholders(this.menuCard.templateId))
                .then((response) => {
                    this.cPlaceholders = response.data;
                })
                .catch((error) => {
                    this.$emit('error', error.response.data.errors);
                });
        },
        isPlaceholdersError: function () {
            return this.errors !== null && 'placeholders' in this.errors;
        },
        cleanPlaceholderName: function (placeholder) {
            return placeholder.replace(/^__([A-Z]{3}__)/, '')
                .replace('__', '')
                .replace('_', ' ');
        },
        dateTimeInputFormat: function (date) {
          if (date === '') {
            const [d] = new Date().toISOString().split('T');
            return `${d}T00:00`;
          }

          let dateParts = date.split(' ');

          if (dateParts.length > 1 || !dateParts.includes('T')) return dateParts.join('T');
          else return `${date}T00:00`;
        },
        updatePlaceholder: function (index, placeholder) {
            this.cPlaceholders[index] = placeholder;
            this.cPlaceholders = [ ...this.cPlaceholders ];
        }
    },
}
</script>

<style scoped>

</style>
