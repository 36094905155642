const apiUrl = (withQuery = false) =>
{
    return withQuery ? '/api' + window.location.pathname + window.location.search : '/api' + window.location.pathname;
}

const validateTemplateNameUrl = () =>
{
    return apiUrl() + '/validate/name';
}

const validateTemplateCountriesUrl = () =>
{
    return apiUrl() + '/validate/countries';
}

const uploadTemplateZipUrl = () =>
{
    return apiUrl() + '/upload';
}

const validateMenuCardNameAndCustomer = () =>
{
    return apiUrl() + '/validate/name-and-customer';
}

const loadPlaceholders = (templateId) =>
{
    return `/api/templates/${templateId}/placeholders`;
}

const loadImageAreas = (templateId) =>
{
    return `/api/templates/${templateId}/imageAreas`;
}
const validatePlaceholders = (templateId) =>
{
    return `/api/templates/${templateId}/validate/placeholders`;
}

const uploadMenuCardImages = () =>
{
    return apiUrl() + '/upload';
}

export {
    apiUrl,
    validateTemplateNameUrl,
    validateTemplateCountriesUrl,
    uploadTemplateZipUrl,
    validateMenuCardNameAndCustomer,
    loadPlaceholders,
    loadImageAreas,
    validatePlaceholders,
    uploadMenuCardImages,
}
