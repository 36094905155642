<template>
    <div class="upload-container">
        <label for="upload" class="btn upload-btn" hidden>{{ translate('ADD_ZIP') }}</label>
        <input type="file"
               id="upload"
               name="upload"
               @change="singleUpload"
               hidden
        >
        <div id="upload-drop"
             class="upload-drop"
             @dragover="onDrag"
             @dragleave="onDragEnd"
             @drop="onDrop"
             @click="clickUpload"
             :class="[isUploadError() ? 'error' : '']"
        >
            <span class="desktop">
                {{ translate('DRAG_AND_DROP_ZIP_UPLOAD') }}
            </span>
            <span class="mobile">
                {{ translate('TOUCH_TO_UPLOAD_ZIP') }}
            </span>
        </div>

        <h3>{{ translate('TEMPLATE_PREVIEW') }}</h3>
        <iframe @load="resizeIframe" id="template-preview"></iframe>
    </div>
</template>

<script>
    import * as Loader from "../../modules/Loader";
    import * as ApiUrl from "../../modules/ApiUrl";
    import axios from "axios";

    export default {
        name: "ZipStep",
        props: {
            previewHtml: String,
            translations: Object,
        },
        data () {
            return {
                uploadErrors: {},
            }
        },
        mounted () {
            this.setIframeContent(this.computedHtml);
        },
        computed: {
            computedHtml: {
                get () {
                    return this.previewHtml;
                },
                set (val) {
                    this.$emit('changeHtml', val);
                    this.setIframeContent(val)
                }
            }
        },
        methods: {
            translate: function (translationKey) {
              return this.translations[translationKey];
            },
            resizeIframe: function (event) {
                const iframe = event.currentTarget;
                const iframeDoc = iframe.contentWindow.document;

                iframe.width = window.innerWidth >= 1200 ? '1200px' : window.innerWidth + 'px';
                iframe.height = iframeDoc.body.scrollHeight + 'px';
            },
            setIframeContent: function (content) {
                const iframe = document.getElementById('template-preview');
                const iframeDoc = iframe.contentWindow.document;

                iframeDoc.open()
                iframeDoc.write(content)
                iframeDoc.close()
            },
            clickUpload: function (event) {
                document.getElementById('upload').click();
            },
            isUploadError: function () {
                return 'zip' in this.uploadErrors;
            },
            singleUpload: async function (event) {
                Loader.apply();

                const input = event.currentTarget;
                const file = input.files[0];
                const url = ApiUrl.uploadTemplateZipUrl();

                return await this.apiUploadFile(url, this.createRequestBody(file))
                    .then(response => response.data)
                    .then(data => this.computedHtml = data.previewHtml)
                    .then(() => {
                        Loader.remove();
                        this.$delete(this.uploadErrors, 'zip')
                        this.$emit('uploadError', {})
                        document.getElementById('step-36').classList.add('completed')
                    })
                    .catch((error) => {
                        this.$delete(this.uploadErrors, 'zip')
                        this.$set(this.uploadErrors, 'zip', error.response.data.errors)
                        this.$emit('uploadError', this.uploadErrors.zip)
                    })
                    .finally(() => Loader.remove());
            },
            apiUploadFile: async function (url, body) {
                return await axios.post(url, body,{
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            },
            createRequestBody: function (file) {
                const formData = new FormData;
                formData.append('file', file, file.name);

                return formData;
            },
            killDefaults: function (event)
            {
                event.preventDefault();
                event.stopPropagation();
            },
            onDrop: function (event)
            {
                this.killDefaults(event);
                this.onDragEnd(event);

                const input = document.querySelector('#upload');
                input.files = event.dataTransfer.files;
                input.dispatchEvent(new Event('change'));
            },
            onDrag: function (event)
            {
                this.killDefaults(event);

                const dropZone = event.currentTarget;
                dropZone.classList.add('file-over');
            },
            onDragEnd: function (event)
            {
                this.killDefaults(event);

                const dropZone = event.currentTarget;
                dropZone.classList.remove('file-over');
            }
        }
    }
</script>

<style scoped>

</style>
