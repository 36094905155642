const apply = () =>
{
    const loader = getLoader();
    loader.classList.remove('hidden');
};

const remove = () =>
{
    const loader = getLoader();
    loader.classList.add('hidden');
};

const toggle = () =>
{
    const loader = getLoader();
    loader.classList.toggle('hidden');
}

export { apply, remove, toggle };

const getLoader = () =>
{
    return document.getElementById('loader');
};
