<template>
    <div class="menu-card-step">
        <div class="name">
            <label>
                {{ translate('NAME_UC') }}
                <input v-model="cMenuCard.name"
                       :class="[isNameError() ? 'error' : '']"
                       :placeholder="translate('NAME_OF_DIGITAL_CONTENT')">
            </label>
        </div>
        <br>
        <div class="customer">
            <CustomerSelect :value="cMenuCard.customerId"
                            :translations="translations"
                            @changeCustomer="cMenuCard.customerId = $event"/>
        </div>
    </div>
</template>

<script>

import CustomerSelect from "./CustomerSelect";

export default {
    name: "NameAndCustomerStep",
    components: { CustomerSelect },
    props: {
        menuCard: Object,
        errors: Object,
        translations: Object,
    },
    computed: {
        cMenuCard: {
            get() {
                return this.menuCard;
            },
            set(val) {
                this.$emit('updateMenuCard', val);
            }
        }
    },
    methods: {
        translate: function (translationKey) {
          return this.translations[translationKey];
        },
        isNameError: function () {
            return this.errors !== null && 'name' in this.errors;
        },
    }
}
</script>

<style scoped>

</style>
